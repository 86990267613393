import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import LinksAccordion from 'components/LinksAccordion/LinksAccordion';
import { useLayoutContext } from 'context/LayoutContext';
import TaxInformationLinks from 'utils/taxInformation';
import ReadyToTrade from 'components/ReadyToTrade';
import SmallPrint from 'components/SmallPrint';
import Section from 'components/Section';
import Headline from 'components/Headline';
import SEO from 'components/SEO';

const MainPageWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }

  ${SmallPrint} {
    font-style: normal;
  }
`

const HeadlineSection = styled(Section)`
  ${Headline} {
    text-align: left;
    padding-bottom: 1rem;
  }
`

const MainPageSection = styled(Section)`
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding-bottom: 2.5rem;
  }
`;

const StyledDiv = styled.div`
  .step-wrapper {
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;

    p {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    .step-number {
      color: ${prop('theme.colors.primaryRed')};
      margin-right: 0.3125rem;
    }
  }
`;

function TaxInformation() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Tax Information' />
      <MainPageWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            Tax Information
          </Headline>
          <hr />
        </HeadlineSection>
        <MainPageSection withPadding>
          <StyledDiv>
            <LinksAccordion sections={TaxInformationLinks} />
          </StyledDiv>
        </MainPageSection>
        <ReadyToTrade />
      </MainPageWrapper>
    </Layout>
  )
}

export default TaxInformation;
