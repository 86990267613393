import { Collapse } from 'reactstrap';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import chevronLoad from 'images/icon-chevron-load-red.svg';
import chevronBase from 'images/icon-chevron.svg';

const StyledHeader = styled.div`
  color: ${prop('theme.colors.black')};
  cursor: pointer;
  background-color: white;
  margin: 1.25rem;
  font-size: 1.225rem;
  font-weight: 500;
  display: flex;
  font-family: ${prop('theme.fonts.default')};
  display: flex;
  justify-content: space-between;

  &:after {
    content: "";
    display: block;
    height: .75rem;
    width: .75rem;
    background-repeat: no-repeat;
    min-height: .75rem;
    min-width: .75rem;
    margin-top: .5rem;
    background-image: url(${chevronBase})
  }

  ${ifProp('$isOpen', css`
    &:after {
      background-image: url(${chevronLoad});
    }
  `)};
`;

const StyledList = styled.ul`
  padding-top: .15rem;
`;

const StyledSection = styled.div`
  margin-bottom: 0.3125rem;

  &:not(:last-child):after {
    display: block;
    content: '';
    border-bottom: 1px solid rgba(0,0,0,.1);
    color: lightgray;
  }
`;
const LinkCount = styled.span`
  color: ${prop('theme.colors.gray')};
`;

const StyledLink = styled.li`
  margin: 0.3125rem;

  &&& {
    a {
      text-decoration: none;
      font-size: 1.125rem;
      font-family: 'Open Sans';

      &:hover {
        color: ${prop('theme.colors.primaryRed')};
      }
    }
  }
`

function LinksAccordionItem({ section, index }) {
  const [isOpen, setIsOpen] = useState(index === 0);

  return (
    <StyledSection key={index}>
      <StyledHeader
        $isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        data-event={index}
      >
        <span>
          {section.headline}
          <LinkCount>{' '}({section.links.length})</LinkCount>
        </span>
      </StyledHeader>
      <Collapse isOpen={isOpen}>
        <StyledList>
          {section.links.map((link, i) => (
            <StyledLink key={`styledlink_${i}`}>
              <a target='_blank' href={link.href} index={i} rel='noopener noreferrer'>
                {link.title}
              </a>
            </StyledLink>
          ))}
        </StyledList>
      </Collapse>
    </StyledSection>
  );
}

LinksAccordionItem.propTypes = {
  section: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired
}

export default LinksAccordionItem;
