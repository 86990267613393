import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LinksAccordionItem from './LinksAccordionItem';

const StyledAccordion = styled.div`
  margin-bottom: 1rem;
`;

function LinksAccordion({ sections }) {
  return (
    <>
      {sections.length > 0 && (
        <StyledAccordion>
          {sections.map((section, i) =>
            <LinksAccordionItem key={section.key} index={i} section={section} />
          )}
        </StyledAccordion>
      )}
    </>
  );
}

LinksAccordion.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default LinksAccordion;
