import externalLinks from "./externalLinks";

const links = [
  {
    "headline": "International Accounts",
    "links": [
      {
        "title": "Form 1042-S Guide (for international accounts)",
        "href": externalLinks.form1042
      },
      {
        "title": "Taxes FAQ for International Accounts",
        "href": externalLinks.taxesFaqInternationalAccounts
      },
      {
        "title": "Tax Reconciliation for Internatinal Accounts",
        "href": externalLinks.taxReconciliationInternational
      },
      {
        "title": "Tax Withholding Rates for International Customers",
        "href": externalLinks.taxWithholdingInternational
      },
    ]
  },
  {
    "headline": "Tax Form Guides",
    "links": [
      {
        "title": "Consolidated 1099 Guide",
        "href": externalLinks.consolidated1099
      },
    ]
  },
  {
    "headline": "Tax Center & Documents",
    "links": [
      {
        "title": "Tax Center Location",
        "href": externalLinks.taxCenterLocation
      },
      {
        "title": "Tax Form Calendar",
        "href": externalLinks.taxFormCalendar
      },
      {
        "title": "Which tax forms can I expect?",
        "href": externalLinks.expectedTaxForm
      },
      {
        "title": "Tax Form Corrections",
        "href": externalLinks.taxFormCorrections
      },
    ]
  },
  {
    "headline": "Data Imports/Exports",
    "links": [
      {
        "title": "How to Import Data to TurboTax",
        "href": externalLinks.importDataTurboTax
      },
      {
        "title": "How to Import Data to H&R Block Tax Software",
        "href": externalLinks.importDataHandR
      },
      {
        "title": "How to Import Data into TaxAct",
        "href": externalLinks.importDataTaxAct
      },
      {
        "title": "How to Import Data to TradeLog",
        "href": externalLinks.importDataTradeLog
      },
      {
        "title": "Employer Identification Number (EIN) Location",
        "href": externalLinks.EinLocation
      },
      {
        "title": "Where do I download my Year-to-Date data? (1040-ES)",
        "href": externalLinks.downloadYearToDateData
      },
    ]
  },
  {
    "headline": "Tax Treatment & Rules",
    "links": [
      {
        "title": "Tax Lots (FIFO & LIFO)",
        "href": externalLinks.taxLots
      },
      {
        "title": "$0.00 Cost Basis on Short Equity Options",
        "href": externalLinks.costBasis
      },
      {
        "title": "Wash Sales/Disallowed Loss",
        "href": externalLinks.washSalesDisallowedLoss
      },
      {
        "title": "Total Commissions & Fee on Tax Forms",
        "href": externalLinks.commissionsAndFeesTaxForm
      },
      {
        "title": "Short-Term vs. Long-Term and 1256 Contracts",
        "href": externalLinks.shortTermLongTermContracts
      },
      {
        "title": "Taxes on options exercises, assignments, and rolls",
        "href": externalLinks.taxesOnOptionsExercisesAssignmentsAndRolls
      },
      {
        "title": "How to Report Section 1256 Contracts (Futures and Cash-Settled Index Options)",
        "href": externalLinks.reportSection1256
      },
      {
        "title": "How are Dividends Taxed?",
        "href": externalLinks.dividendsTaxed
      },
    ]
  }
]

export default links;